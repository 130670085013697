import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer__container container'>
        <span className='footer__copy'>
          &#169; 2023 Stephin Reji. All rights reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
